.signInBetaModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 50px;
  @media only screen and (max-width: 745px) {
    padding: 35px 25px;
  }
  svg path:first-child {
    @apply fill-bluesmart;
  }
}
.container {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  *:not(.btn) {
    color: #000;
  }
  h2 {
    margin-top: 22px;
    font-size: 30px;
    font-weight: 700;
  }
  p {
    letter-spacing: -0.3px;
    line-height: 24px;
    font-size: 16px;
    max-width: 370px;
    text-align: center;
  }

  .p1 {
    margin-top: 15px;
  }
  .p2 {
    margin-top: 20px;
    max-width: 391px;
  }
  .btnContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 17px;
    padding-right: 17px;
    gap: 10px;
    margin-top: 30px;
  }
  .btnContainer .btn {
    border-radius: 8px;
    height: 36px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    opacity: 0.94;
    transition: all 0.6s ease;
    font-weight: 600 !important;
  }

  .btnContainer {
    .btn {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      &:hover {
        opacity: 1;
      }
      &.gray {
        background: #999;
      }
      &.smart {
        background: #1c86fa;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    h2 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
      line-height: 1.1rem;
    }
  }
}
