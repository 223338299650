.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  .formContainer {
    display: flex;
    flex-direction: column;
    width: 600px;
    background: #ffffff;
    border-radius: 32px;
    padding: 60px 100px;
    @media only screen and (max-width: 768px) {
      width: 100vw;
      padding-left: 15px;
      padding-right: 15px;
    }
    .logo {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 15px;
    }
    .subTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
  .linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    align-items: flex-start;
    & a {
      @apply flex items-start;
      &:hover {
        @apply text-bluesmart;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100vw;
      flex-direction: column;
      align-items: center;
    }
    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .googleButtonWrapper {
    margin-top: 40px;
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
    }
  }
}
